import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { translations } from '../../../config'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as formViewSelectors, actions as formViewActions } from '../../../store/modules/formViews'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'
import { actions as reportDetailsActions, selectors as reportDetailsSelectors } from '../../../store/modules/reportDetails'
import { useSelector } from 'react-redux'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import Editable from '../../../components/Editable'
import P from '../../../components/P'
import ReportForm from './components/ReportForm/ReportForm'
import IconText from '../../../components/IconText'
import Warning from '@material-ui/icons/Warning'
import { Grid, Hidden } from '@material-ui/core'
import ReviewModal from '../../CaseManagement/CaseSearchScreen/Modals/ReviewModal'
import { goBack, push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import modalService from '../../../services/modalService'
import Button from '../../../components/Button'
import FileBrowserEnhancer from '../../../components/FileBrowserEnhancer'
import Notification from './components/Notification'
import toastService from '../../../services/toastService'
import getFormSchemaLabel from '../../../helpers/getFormSchemaLabel'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { reviewStatuses } from '../../../store/modules/submissionReports/constants'
import { withStyles, useTheme, styled  } from '@material-ui/core/styles'
import { useExistingReportErrors } from '../hooks'
import { useRouteMatch } from 'react-router-dom'
import style from './style'
import digitalStoreSdk from '../../../digitalStoreSdk'
import ReviewInfo from '../../../components/ReviewInfo'

const NewReportBeta = Editable(({ classes, location, match }) => {
  const dispatch = useDispatch()
  
  const isReviewMatch = useRouteMatch('/reports/:id/review/:submissionReportId') || useRouteMatch('/reports/:id/review/anonymised/:submissionReportId')
  const isReview = !!isReviewMatch
  const isAnonymisedMatch = useRouteMatch('/reports/:id/review/anonymised/:submissionReportId')
  const submissionReportId = _.get(match, 'params.submissionReportId')
  const reportId = !isReview ? _.get(match, 'params.id') : _.get(match, 'params.reportId')
  const followUpNotificationId = _.get(match, 'params.notificationId')

  const reportDetailsFormSchemaName = useSelector(reportDetailsSelectors.getFormSchemaName)
  const schemaName = useSelector(formViewSelectors.getSelectedSchemaName) || reportDetailsFormSchemaName
  const formSchemaName = useSelector(formViewSelectors.getSelectedFormSchemaName) || schemaName

  const formSchemaId = useSelector(formViewSelectors.getSelectedFormSchemaId)
  const isWorkspace = useSelector(authSelectors.isLoggedIntoWorkspace)
  const selectedOrganisation = useSelector(authSelectors.getSelectedOrganisation)
  const skipReadOnlyView = useSelector(platformSelectors.getHasSkipReportReadOnlyViewEnabled)

  const showUpdates = useSelector(authCombinedSelectors.userCanSeeReportUpdates)
  const hasNewerVersion = useSelector(reportDetailsSelectors.getHasNewerVersion)
  const showVersionWarning = hasNewerVersion && showUpdates

  const title = _getTitle({ formTitle: schemaName, isNewFormMode: true })
  const pageTitle = useSelector(platformSelectors.getPageTitle)(title)
  const existingReport = useSelector(reportDetailsSelectors.getExistingReport)

  const [formState, setFormState] = useState()
  const [isLoading, setIsLoading] = useState()
  const [initialValues, setInitialValues] = useState()
  const [receiverStatus, setReceiverStatus] = useState()
  const [organisationDetails, setOrganisationDetails] = useState()
  const [productDetails, setProductDetails] = useState()
  const [isReadOnly, setIsReadOnly] = useState(false)
  const isReportEditable = useSelector(reportDetailsSelectors.getIsReportEditable)
  const [isNewReport, setIsNewReport] = useState(false)
  const [reportReview, setReportReview] = useState({})
  const [anonymisedReport, setAnonymisedReport] = useState()
  const fetchReport = async (isAnonymisedMatch) => {
    try {
      const report = await digitalStoreSdk.submissions.fetchSubmissionReportById({
        submissionReportId,
        organisationId: selectedOrganisation.id,
      })
      setReceiverStatus(report.receiverStatus)
  
      if (isAnonymisedMatch) {
        setIsLoading(true)
        const anonymisedReportResponse = await digitalStoreSdk.reports.fetchAnonymisedReportBySubmissionReportId({ submissionReportId })
        if (anonymisedReportResponse?.reportJSON) {
          const anonymisedReportValues = _.isString(anonymisedReportResponse.reportJSON)
            ? JSON.parse(anonymisedReportResponse.reportJSON)
            : anonymisedReportResponse.reportJSON
  
          setAnonymisedReport(anonymisedReportValues)
          setInitialValues(anonymisedReportValues)
        }
        setIsLoading(false)
      } else if (isReview && report.receiverStatus !== 'IN_REVIEW') {
        setIsLoading(true)
        const reportReview = await digitalStoreSdk.submissions.fetchSubmissionReportReviewById({ submissionReportId })
        setReportReview(reportReview)
        setIsLoading(false)

      }

    } catch (error) {
      console.error('Error fetching report data:', error)
    }
  }
  useExistingReportErrors(existingReport)

  useEffect(() => {
    if (reportId) {
      if (reportId !== 'new') {
        setIsReadOnly(!skipReadOnlyView)
      } else {
        setIsNewReport(true)
      }
    }
    if(isReview){
      setIsReadOnly(true)
    }
  }, [reportId, isReview])

  useEffect(() => {
    const product = _.get(location, 'state.product')
    if (product) {
      setProductDetails(product)
    }
    if (!isWorkspace && selectedOrganisation) {
      setOrganisationDetails(selectedOrganisation.details)
    }
    }, [location])


    useEffect(() => {
      if (submissionReportId && selectedOrganisation) {
        fetchReport(isAnonymisedMatch)
      }
      
      return () => {
        dispatch(formViewActions.selectReport(null))
      }
    }, [])
   
  const onReviewSuccess = useCallback(async () => {
    await fetchReport()
  },[fetchReport])

  const onFormStateChange = useCallback((state) => {
    setFormState(state)
  },[setFormState])

  const onReject = useCallback(() => {
    modalService.open({
      component: ReviewModal,
      title: translations('Report Review - Confirmation - Title - Reject'),
      message: translations('Report Review - Confirmation - Message - Reject'),
      modalIndex: 2,
      receiverStatus,
      reviewStatus: reviewStatuses.REJECT,
      submissionReportId,
      onReviewSuccess,
    })
  },[modalService])

  const onApprove = () => {
    modalService.open({
      component: ReviewModal,
      title: translations('Report Review - Confirmation - Title - Approve'),
      message: receiverStatus === 'REJECTED' ? translations('Report Review - Confirmation - Message - Revert Rejection') : translations('Report Review - Confirmation - Message - Approve'),
      modalIndex: 2,
      receiverStatus,
      reviewStatus: reviewStatuses.APPROVE,
      submissionReportId,
      onReviewSuccess,
    })
  }

  const onSuccess = (props) => {
    const extReportId = _.get(props, 'response.report.extReportId')
    dispatch(push('/report-management'))
    toastService.action({
      type: 'success',
      message: translations('New Report Beta - Success submitting report', { reportId: extReportId }),
      autoHideDuration: 6000
    })
  }

  const onError = (error) => {
    toastService.action({
      type: 'error',
      message: translations('New Report Beta - Error submitting report', { error: error.message }),
      autoHideDuration: 6000
    })
  }

  const onSaveDraftSuccess = (props = {}) => {
    const { report: { extReportId } = {} } = props
    dispatch(push('/report-management'))
    toastService.action({
      type: 'success',
      message: translations('New Report Beta - Draft Success', { reportId: extReportId }),
      autoHideDuration: 6000
    })
  }

  const onSaveDraftError = (error) => {
    toastService.action({
      type: 'error',
      message: translations('New Report Beta - Error saving draft', { error }),
      autoHideDuration: 6000
    })
  }

  const _renderWarning = ({ classes }) => {
    return (
      <div className={classes.warningContainer}>
        <IconText iconSize={26} fontSize={16} icon={Warning} text={translations('A newer version of this report is available')} />
      </div>
    )
  }

  const onLoadExisting = (e) => {
    setIsLoading(true)
    const file = e.target.files[0]
    dispatch(reportDetailsActions.loadExistingReport({ file, formSchemaId })).then(result => {
      const messageNumber = _.get(result, 'messagenumb')
      if (_.isNil(messageNumber) || _.isEmpty(messageNumber)) {
        const omitted = _.omit(result, ['messagenumb'])
        setInitialValues(omitted)
      } else {
        setInitialValues(result)
      }
      setIsLoading(false)
    })
      .catch(error => {
        setIsLoading(false)
        toastService.action({
          type: 'error',
          message: translations('New Report Beta - Error loading existing', { error: error.message }),
          autoHideDuration: 6000
        })
      })
  }

  const loadableReports = _.includes([
    'devicesReport',
    'mhraR2Report',
    'fscaReport'
  ], formSchemaName)

  const ReportFormRender = (<ReportForm
    isWorkspace={isWorkspace}
    onFormStateChange={onFormStateChange}
    onSuccess={onSuccess}
    onError={onError}
    onSaveDraftSuccess={onSaveDraftSuccess}
    onSaveDraftError={onSaveDraftError}
    initialValues={initialValues}
    organisationDetails={organisationDetails}
    productDetails={productDetails}
    isReadOnly={isReadOnly}
    isNewReport={isNewReport}
    anonymisedReport={anonymisedReport}
  />)

  const RejectButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: 'white',
    },
    '&:focus': {
      outline: `2px solid ${theme.palette.error.dark}`,
    },
    '&:active': {
      backgroundColor: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
      color: 'white',
    },
  }))
  
  const ApproveButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: 'white',
    },
    '&:focus': {
      outline: `2px solid ${theme.palette.success.dark}`,
    },
    '&:active': {
      backgroundColor: theme.palette.success.dark,
      borderColor: theme.palette.success.dark,
      color: 'white',
    },
    marginRight: '20px',
  }))

  const ReviewActions = (
    <>
    <div
          style={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#fff',
            padding: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
            borderTop: '1px solid #e0e0e0'
          }}
        >
          {/* Centered buttons */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flex: 1
            }}
          >
            {receiverStatus === 'IN_REVIEW' && _.isEmpty(reportReview) && (
              <>
              <ApproveButton variant='outlined' onClick={onApprove} aria-label={translations('Report Review - Approve')}>
              {translations('Report Review - Approve')}
            </ApproveButton>
              <RejectButton variant='outlined' onClick={onReject} aria-label={translations('Report Review - Reject')}>
                {translations('Report Review - Reject')}
              </RejectButton>
              </>
            )}
          </div>
        </div>
    </>
  )
  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar onClick={() => onBackClick({ dispatch, isDirty: _.get(formState, 'dirty'), schemaName, isReview })} />
        )}
        centerContent={(
          <>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </>
        )}
        rightContent={
          <>
            {!isReview && !anonymisedReport && loadableReports &&
              <FileBrowserEnhancer
                accept={['.xml']}
                onChange={onLoadExisting}
                renderContent={({ onClick }) => (
                  <Button onClick={onClick} raised variant='contained' style={{ marginRight: 20 }}>
                    {translations('Load Existing Report')}
                  </Button>
                )}
              />
            }
            {isReadOnly && isReportEditable && !skipReadOnlyView && !isReview && (
              <Button
                onClick={() => setIsReadOnly(false)}
                raised
                variant='contained'
                buttonType='primary'
                style={{ marginRight: 20 }}
              >
                {translations('Update')}
              </Button>
            )}
          </>
        }
      />
      {showVersionWarning && !followUpNotificationId && _renderWarning({ classes })}
      {!isLoading && followUpNotificationId ? (
        <Grid container alignItems="center">
          {/* Check if review data exists */}
          {isReview && !_.isEmpty(reportReview) && (
            <Grid item xs={12} sm={4} md={3} lg={3} className={classes.scrollableGridItem}>
              <Notification notificationId={followUpNotificationId} />
            </Grid>
          )}
  
          {/* Center the main form when the left column is not present */}
          <Grid
            item
            xs={12}
            sm={isReview && !_.isEmpty(reportReview) ? 8 : 12}  
            md={isReview && !_.isEmpty(reportReview) ? 9 : 8}  
            lg={isReview && !_.isEmpty(reportReview) ? 9 : 6}  
            className={classes.scrollableGridItem}
            container
            justifyContent={isReview && !_.isEmpty(reportReview) ? "flex-start" : "center"}  
          >
            <div className={classes.formContainer}>
              {!isLoading && ReportFormRender}
            </div>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.formContainer}>
          <Grid container>
            {isReview && !_.isEmpty(reportReview) && (
              <Grid item xs={12} sm={4} md={3} lg={3} className={classes.scrollableGridItem}>
                <ReviewInfo reportReview={reportReview} className={classes.reviewInfoPanel} isLoading={isLoading} onApprove={onApprove}/>
              </Grid>
            )}
            {/* Main form: Centered if no review panel */}
            <Grid
              item
              xs={12}
              sm={isReview && !_.isEmpty(reportReview) ? 8 : 12}
              md={isReview && !_.isEmpty(reportReview) ? 9 : 12}  
              lg={isReview && !_.isEmpty(reportReview) ? 9 : 12}  
              className={classes.scrollableGridItem}
            >
              <div className={classes.formContainer}>
                {!isLoading && ReportFormRender}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
     {isReview && ReviewActions}
    </div>
  )
})


const _getTitle = ({ editing, isNewFormMode, reportStatus, formTitle }) => {
  const formType = reportStatus === 'draft' ? 'Draft' : 'Report'
  const isEditing = !!editing ? 'Edit' : 'View'
  if (isNewFormMode) {
    return getFormSchemaLabel(formTitle) || `Standard`
  }
  return translations(`${isEditing} ${formType}`)
}

const onBackClick = ({ dispatch, isDirty, schemaName, isReview }) => {
  if (!isDirty) {
    dispatch(goBack())
    return
  }

  let warningMessage
  switch (schemaName) {
    case 'devicesReport':
      warningMessage = 'Discard Devices Report Notice Text'
      break
    case 'sabreSAR':
    case 'sabreSAE':
      warningMessage = 'Discard SAE/SAR Notice Text'
      break
    case 'mhraR2Report':
    case 'mhraR3Report':
      warningMessage = 'Discard Report Notice Text'
      break
    default:
      warningMessage = 'Discard Report Notice Text Default'
  }
  if(!isReview){
    modalService.action({
      title: translations('Warning'),
      text: translations(warningMessage),
      actions: [
        { text: translations('Cancel') },
        {
          text: translations('Discard changes and go back'),
          onClick: () => {
            dispatch(goBack())
          }
        }
      ]
    })
  }
  else{
    dispatch(goBack())
  }
}

export default withStyles(style)(NewReportBeta)
