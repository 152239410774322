import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import digitalStoreSdk from '../../digitalStoreSdk'
import Button from '../../components/Button'
import modalService from '../../services/modalService'
import { translations } from '../../config'

import CompanyAccountRequestForm from '../Login/CompanyAccountRequestForm'

import RejectModal from './rejectModal'

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 150px;
  flex-direction: column;
`

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
`

export default function OrganisationRequests(props) {
  const { changeRequestId } = props.match.params
  const [preview, setPreview] = useState()
  const [changeRequest, setChangeRequest] = useState()

  useEffect(() => {
    void async function fetch(){
      const changeRequest = await digitalStoreSdk.changeRequests.fetchById({ changeRequestId })
      const preview = await digitalStoreSdk.changeRequests.previewChangeRequestCommit({ changeRequestId })

      setChangeRequest(changeRequest)
      setPreview(preview)
    }()
  }, [changeRequestId])

  useEffect(() => {
    if (changeRequest && changeRequest.approvalStatus !== 'PENDING') {
      window.location.href = '/'
    }
  }, [changeRequest])

  const initialValues = {
    ...preview?.organisation.details,
    ...preview?.requesterDetails,
    name: preview?.organisation.name
  }

  const onApprove = useCallback(async () => {
    await digitalStoreSdk.changeRequests.approveChangeRequest({ changeRequestId })
    window.location.href = '/'
  }, [changeRequestId])

  const onReject = useCallback(async () => {
    modalService.open({
      component: RejectModal,
      onDismiss: () => modalService.close(),
      onConfirm: async ({ rejectionMessage }) => {
        await digitalStoreSdk.changeRequests.declineChangeRequest({ changeRequestId, rejectionMessage })
        window.location.href = '/'
        modalService.close()
      },
      title: translations('Organisation Requests - Reject new organisation')
    })
  }, [changeRequestId])

  if (changeRequest && changeRequest.approvalStatus !== 'PENDING') {
    return null
  }

  return (
    <>
      {preview ? (
        <FormContainer>
          <CompanyAccountRequestForm initialValues={initialValues} readOnly />
          <ActionsContainer>
            <Button buttonType="secondary" onClick={onReject}>{translations('Change Request - Reject')}</Button>
            <Button buttonType="primary" onClick={onApprove}>{translations('Change Request - Approve')}</Button>
          </ActionsContainer>
        </FormContainer>
      ) : (
        <div>Loading...</div>
      )}
    </>
  )
}
