import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import ImageControlPointDuplicateIcon from '@material-ui/icons/FileCopy'

import { Can as AbilityCan, useAbilities } from '../../../ability/ability-context'

import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { translations } from '../../../config'
import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Button from '../../../components/Button'
import Heading from '../../../components/Heading'
import P from '../../../components/P'
import Can from '../../../components/Can'
import BlockList, { BlockListItem } from '../../../components/BlockList'
import { FormBody, FormError } from '../../../components/Form'
import FormContentBox from '../../../components/FormContentBox'
import ManageContentBox from '../../../components/ManageContentBox'
import * as schema from '../formSchemas/organisationDetailsSchema'
import Editable from '../../../components/Editable'
import { constants as organisationDetailsConstants } from '../../../store/modules/organisationDetails'
import { constants as ssoProviderConstants } from '../../../store/modules/ssoProvider'

import DrugsListsPanel from './DrugsListsPanel'
import DrugMetaPanel from './DrugMetaPanel'
import ReceiverMeta from './ReceiverMeta'

import styles from './style'
import { getHasOrganisationInvitesEnabled } from '../../../store/modules/platforms/selectors'
import OrganisationInviteModal from '../../Users/UsersSearchScreen/UsersMoreMenu/OrganisationInviteModal'
import { getCurrentOrganisationId } from '../../../store/modules/organisationDetails/selectors'
import modalService from '../../../services/modalService'
import { getCurrentUser } from '../../../store/modules/auth/selectors'
import { asyncActions as organisationInviteAsyncActions } from '../../../store/modules/organisationInvites'
import toastService from '../../../services/toastService'
import LocationManagement from './LocationManagement/LocationManagement'


const OrganisationDetailsForm = Editable(FormContentBox(organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME))
const OrganisationContactForm = Editable(FormContentBox(organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME))
const OrganisationConfigForm = Editable(FormContentBox(organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME))
const ReceiverConfigForm = Editable(FormContentBox(organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME))
const SSOConfigForm = Editable(FormContentBox(ssoProviderConstants.EDIT_SSO_FORM_NAME))
const OrganisationWHODrugListForm = Editable(FormContentBox(organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME))
const OrganisationCommunicationsForm = Editable(FormContentBox(organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME))
const OrganisationAcknowledgementEmailsForm = Editable(FormContentBox(organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME))
const OrganisationNewsForm = Editable(FormContentBox(organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME))
const OrganisationProfessionsForm = Editable(FormContentBox(organisationDetailsConstants.ORGANISATION_PROFESSIONS_FORM_NAME))
const OrganisationProfessionGroupsForm = Editable(FormContentBox(organisationDetailsConstants.ORGANISATION_PROFESSION_GROUPS_FORM_NAME))
const ReportingOrganisationContactDetailsForm = Editable(FormContentBox(organisationDetailsConstants.REPORTING_ORGANISATION_CONTACT_DETAILS_FORM_NAME))

const renderTranslations = (translationList) => {
  return (
    <BlockList>
      {_.map(translationList, (item, i) => {
        const { isoCode, total } = item
        const language = translations(`Language - ${isoCode}`)
        const label = `${translations('Manage Translations - List Item', { language, total })}`
        return (
          <BlockListItem
            label={label}
            key={isoCode}
          />
        )
      })}
    </BlockList>
  )
}

const renderPages = ({ pageList, classes }) => {
  const pages = _.map(pageList, (item, i) => {
    return (
      <BlockListItem
        label={item.title}
        key={item.languageId}
      />
    )
  })
  if (pages.length > 0) {
    return (
      <BlockList>
        {pages}
      </BlockList>
    )
  }
  else {
    return <Heading
      variant='h3'
      className={classes.noDataMessage}
    >{translations('Pages - No Pages Found')}</Heading>
  }
}

const ViewOrganisationFormWrapper = ({
  classes,
  initialValues = {},
  onSubmit,
  onSubmitProfessions,
  professionsSchema,
  professionsInitialValues,
  onSubmitProfessionGroups,
  professionGroupsSchema,
  professionGroupsInitialValues,
  onClickManageTranslations,
  organisationTranslations,
  canEditOrg,
  canEditOrgFundamentals,
  organisationConfigurationSchema,
  pageTitle,
  width,
  onDuplicate,
  orgReceivers,
  ssoInitialValues,
  ssoOnSubmit,
  receiverOptions,
  organisationPages,
  onClickManagePages,
  orgContactDetailsFormValues
}) => {
  if (orgReceivers) {
    _.set(initialValues, 'receiverId', _.map(orgReceivers, 'receiverId'))
  }
  const ability = useAbilities()
  const dispatch = useDispatch()
  const canUpdateGeneralConfiguration = ability.can('updateOrganisation', 'organisationManagement')
  const canUpdateContactConfiguration = ability.can('updateOrganisationContactDetails', 'organisationManagement')
  const canUpdateOrganisationConfiguration = ability.can('updateOrganisationConfiguration', 'organisationManagement')
  const canUpdateOrganisationReceivers = ability.can('updateOrganisationReceiverConfiguration', 'organisationManagement')
  const canUpdateOrganisationNews = ability.can('updateOrganisationNewsConfiguration', 'organisationManagement')
  const canUpdateOrganisationEmailAcks = ability.can('updateOrganisationEmailConfiguration', 'organisationManagement')
  const canUpdateOrganisationTranslations = ability.can('updateOrganisationTranslations', 'organisationManagement')
  const canUpdateOrganisationPages = ability.can('updateOrganisationPages', 'organisationManagement')
  const canEditReportingOrganisationContactDetails = ability.can('editReportingOrganisationContactDetails', 'organisationManagement')
  const locationManagementEnabled = useSelector(platformSelectors.getHasLocationsManagementEnabled)
  const hasReportSendingMethodManagementEnabled = useSelector(platformSelectors.getHasReportSendingMethodManagementEnabled)
  const hasAnonymisationManagementEnabled = useSelector(platformSelectors.getHasAnonymisationManagementEnabled)
  const hasUserInvitesEnabled = useSelector(getHasOrganisationInvitesEnabled)

  const author = _.get(useSelector(getCurrentUser), 'id')
  const currentOrganisationId = useSelector(getCurrentOrganisationId)

  const onSubmitOrganisationInvite = async (data) => {
    const fields = {
      email: data.email,
      organisationId: currentOrganisationId,
      author
    }
    const result = await dispatch(organisationInviteAsyncActions.createOrganisationInvite(fields)).unwrap()
    if (result.status === 'FULFILLED') {
      toastService.action({
        type: 'success',
        message: translations('Organisation Invite - Successfully sent email', { email: data.email }),
        autoHideDuration: 6000
      })
    }
    modalService.close()
  }

  const openOrganisationInviteModal = () => {
    return modalService.open({
      component: OrganisationInviteModal,
      onSubmit: onSubmitOrganisationInvite
    })
  }

  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1' uppercase>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
        rightContent={(
          <>
            <AbilityCan I='duplicateOrganisation' a='organisationManagement'>
              <Can actions='isAdmin'>
                <Button
                  raised
                  buttonType='primary'
                  className={classes.duplicateButton}
                  onClick={onDuplicate}
                  aria-label={translations('Duplicate')}
                >
                  {width === 'xs' ? <ImageControlPointDuplicateIcon /> : translations('Duplicate')}
                </Button>
              </Can>
            </AbilityCan>
            {
              hasUserInvitesEnabled && (
                <Button
                  raised
                  buttonType='primary'
                  className={classes.duplicateButton}
                  onClick={openOrganisationInviteModal}
                  aria-label={translations('Invite User')}
                >
                  {translations('Invite User')}
                </Button>
              )
            }
          </>

        )}
      />
      <div className={classes.contentContainer}>
        <div className={classes.requiredDeactivateButtonContainer}>
          <P value={translations('required fields')} className={classes.requiredText} />
        </div>
        <FormError givenClass={classes.submitError} />
        <AbilityCan I="viewReportingOrganisationContactDetails" a="organisationManagement">
          <ReportingOrganisationContactDetailsForm
            editable={canEditReportingOrganisationContactDetails}
            enableReinitialize
            initialValues={initialValues}
            givenClass={classes.formContainer}
            onSubmit={onSubmit}
            boxName={translations('Reporting Organisation Contact Details Box Name')}
            formId={organisationDetailsConstants.REPORTING_ORGANISATION_CONTACT_DETAILS_FORM_NAME}>
            <FormBody
              schema={schema.reportingOrganisationContactDetailsFormSchema(orgContactDetailsFormValues).schema}
              globalClasses={{
                col: classes.columnStyle,
                row: classes.rowStyle
              }}
            />
          </ReportingOrganisationContactDetailsForm>
        </AbilityCan>
        <AbilityCan I='viewOrganisation' a='organisationManagement'>
          <OrganisationDetailsForm
            editable={canUpdateGeneralConfiguration && canEditOrgFundamentals}
            enableReinitialize
            initialValues={initialValues}
            givenClass={classes.formContainer}
            onSubmit={onSubmit}
            boxName={translations('Organisation')}
            formId={organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME}>
            <FormBody
              schema={schema.organisationDetails.schema}
              globalClasses={{
                col: classes.columnStyle,
                row: classes.rowStyle
              }}
            />
          </OrganisationDetailsForm>
        </AbilityCan>

        {locationManagementEnabled &&
          (
            <AbilityCan I='viewLocationOrganisationManagement' a='organisationManagement'>
              <LocationManagement
                classes={classes}
                canEditOrg={canEditOrg}
                organisationId={currentOrganisationId}
              />
            </AbilityCan>
          )
        }

        <AbilityCan I='viewOrganisationContactDetails' a='organisationManagement'>
          <OrganisationContactForm
            editable={canUpdateContactConfiguration && canEditOrg}
            enableReinitialize
            initialValues={initialValues}
            givenClass={classes.formContainer}
            onSubmit={onSubmit}
            boxName={translations('Organisation Contact Details')}
            formId={organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME}>
            <FormBody
              schema={schema.organisationContactDetails.schema}
              globalClasses={{
                col: classes.columnStyle,
                row: classes.rowStyle
              }}
            />
          </OrganisationContactForm>
        </AbilityCan>
        <AbilityCan I='viewOrganisationConfiguration' a='organisationManagement'>
          <OrganisationConfigForm
            editable={canUpdateOrganisationConfiguration && canEditOrg}
            enableReinitialize={true}
            initialValues={initialValues}
            givenClass={classes.formContainer}
            onSubmit={onSubmit}
            boxName={translations('Organisation Configuration')}
            formId={organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME}>
            <FormBody
              schema={organisationConfigurationSchema}
              globalClasses={{
                col: classes.columnStyle,
                row: classes.rowStyle
              }}
            />
          </OrganisationConfigForm>
        </AbilityCan>
        <AbilityCan I='viewOrganisationReceiverConfiguration' a='organisationManagement'>
          <ReceiverConfigForm
            editable={canUpdateOrganisationReceivers && canEditOrg}
            enableReinitialize={true}
            initialValues={initialValues}
            givenClass={classes.formContainer}
            onSubmit={onSubmit}
            boxName={translations('Receiver Configuration')}
            formId={organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME}>
           <FormBody
            schema={schema.receiverConfiguration({
                ...receiverOptions,
                hasReportSendingMethodManagementEnabled,
                hasAnonymisationManagementEnabled,

              }).schema}
            layout={schema.receiverConfiguration(receiverOptions).layout}
            globalClasses={{
              col: classes.columnStyle,
              row: classes.rowStyle
            }}
          />
            <AbilityCan I='viewDiagnostics' a='receivers'>
              <ReceiverMeta initialValues={initialValues} receiverOptions={receiverOptions} />
            </AbilityCan>
          </ReceiverConfigForm>
        </AbilityCan>
        <AbilityCan I='viewSectionSsoConfiguration' a='organisationManagement'>
          <SSOConfigForm
            editable={canEditOrg}
            enableReinitialize
            initialValues={ssoInitialValues}
            givenClass={classes.formContainer}
            onSubmit={ssoOnSubmit}
            boxName={translations('SSO Configuration')}
            formId={organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME}>
            <FormBody
              schema={schema.ssoConfiguration.schema}
              layout={schema.ssoConfiguration.layout}
              globalClasses={{
                col: classes.columnStyle,
                row: classes.rowStyle
              }}
            />
          </SSOConfigForm>
        </AbilityCan>
        <AbilityCan I='viewOrganisationNewsConfiguration' a='organisationManagement'>
          <OrganisationNewsForm
            editable={canUpdateOrganisationNews && canEditOrg}
            enableReinitialize
            initialValues={initialValues}
            givenClass={classes.formContainer}
            onSubmit={onSubmit}
            boxName={translations('News Configuration')}
            formId={organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME}>
            <FormBody
              schema={schema.newsConfiguration.schema}
              layout={schema.newsConfiguration.layout}
              globalClasses={{
                col: classes.columnStyle,
                row: classes.rowStyle
              }}
            />
          </OrganisationNewsForm>
        </AbilityCan>
        <AbilityCan I='viewOrganisationNewsConfiguration' a='organisationManagement'>
          <OrganisationCommunicationsForm
            editable={canEditOrg}
            enableReinitialize
            initialValues={initialValues}
            givenClass={classes.formContainer}
            onSubmit={onSubmit}
            boxName={translations('Communications - Edit Form Title')}
            formId={organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME}>
            <FormBody
              schema={schema.communicationsConfiguration.schema}
              layout={schema.communicationsConfiguration.layout}
              globalClasses={{
                col: classes.columnStyle,
                row: classes.rowStyle
              }}
            />
          </OrganisationCommunicationsForm>
        </AbilityCan>
        <AbilityCan I='viewOrganisationEmailConfiguration' a='organisationManagement'>
          <OrganisationAcknowledgementEmailsForm
            editable={canUpdateOrganisationEmailAcks && canEditOrg}
            enableReinitialize
            initialValues={initialValues}
            givenClass={classes.formContainer}
            onSubmit={onSubmit}
            boxName={translations('Acknowledgement Email Configuration')}
            formId={organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME}>
            <FormBody
              schema={schema.acknowledgementEmailsConfiguration.schema}
              layout={schema.acknowledgementEmailsConfiguration.layout}
              globalClasses={{
                col: classes.columnStyle,
                row: classes.rowStyle
              }}
            />
          </OrganisationAcknowledgementEmailsForm>
        </AbilityCan>
        <AbilityCan I='viewOrganisationDrugLists' a='organisationManagement'>
          <AbilityCan I='viewSectionWhoManagement' a='organisationManagement'>
            <OrganisationWHODrugListForm
              editable={canEditOrg}
              enableReinitialize
              initialValues={initialValues}
              givenClass={classes.formContainer}
              onSubmit={onSubmit}
              boxName={translations('WHO Configuration')}
              formId={organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME}>
              <FormBody
                schema={schema.whoConfiguration.schema}
                layout={schema.whoConfiguration.layout}
                globalClasses={{
                  col: classes.columnStyle,
                  row: classes.rowStyle
                }}
              />
            </OrganisationWHODrugListForm>
          </AbilityCan>
          <DrugsListsPanel editable={canEditOrg} />
          <AbilityCan I="viewSectionDrugMeta" a='organisationManagement'>
            <DrugMetaPanel editable={canEditOrg} />
          </AbilityCan>
          <AbilityCan I='viewSectionWhoManagement' a='organisationManagement'>
            <OrganisationProfessionsForm
              editable={canEditOrg}
              enableReinitialize
              initialValues={professionsInitialValues}
              givenClass={classes.formContainer}
              onSubmit={onSubmitProfessions}
              boxName={translations('Professions')}
              formId={organisationDetailsConstants.ORGANISATION_PROFESSIONS_FORM_NAME}>
              <FormBody
                schema={professionsSchema}
                layout={['professions:12']}
                globalClasses={{
                  col: classes.columnStyle,
                  row: classes.rowStyle
                }}
              />
              <FormError translate />
            </OrganisationProfessionsForm>
          </AbilityCan>
          <AbilityCan I='viewSectionWhoManagement' a='organisationManagement'>
            <OrganisationProfessionGroupsForm
              editable={canEditOrg}
              enableReinitialize
              initialValues={professionGroupsInitialValues}
              givenClass={classes.formContainer}
              onSubmit={onSubmitProfessionGroups}
              boxName={translations('Profession Groups')}
              formId={organisationDetailsConstants.ORGANISATION_PROFESSION_GROUPS_FORM_NAME}>
              <FormBody
                schema={professionGroupsSchema}
                layout={['professionGroups:12']}
                globalClasses={{
                  col: classes.columnStyle,
                  row: classes.rowStyle
                }}
              />
              <FormError translate />
            </OrganisationProfessionGroupsForm>
          </AbilityCan>
        </AbilityCan>
        <AbilityCan I='viewOrganisationPages' a='organisationManagement'>
          <ManageContentBox
            name={translations('Pages')}
            rightButtonContent={(
              canEditOrg && canUpdateOrganisationPages
                ? <Button
                  onClick={onClickManagePages}
                  children={translations('Manage')}
                  color='primary'
                  raised
                />
                : null
            )}
            content={renderPages({ pageList: organisationPages, classes })}
          />
        </AbilityCan>
        <AbilityCan I='viewOrganisationTranslations' a='organisationManagement'>
          <ManageContentBox
            name={translations('Manage Translations')}
            rightButtonContent={(
              canEditOrg && canUpdateOrganisationTranslations
                ? <Button
                  onClick={onClickManageTranslations}
                  children={translations('Manage')}
                  color='primary'
                  raised
                />
                : null
            )}
            content={renderTranslations(organisationTranslations)}
          />
        </AbilityCan>
      </div>
    </div>
  )
}

ViewOrganisationFormWrapper.propTypes = {
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired,
  width: PropTypes.string
}

export default withStyles(styles)(ViewOrganisationFormWrapper)
