import React from 'react'
import { Route } from 'react-router'
import PrivateRoute from '../../components/PrivateRoute'
import ReportsMenuScreen from './ReportsMenuScreen'
import SelectReport from './SelectReport'

import NewReportScreen from './NewReportScreen'
import NewReportBeta from './NewReportBeta'
import AdvancedPostReport from './AdvancedPostReport'
import ReportsShareScreen from './ReportsShareScreen'
import SingleReportScreen from './ReportsShareScreen/SingleReportScreen'
import TimedLogoutComponent from '../../components/TimedLogout'

const routes = [
  <PrivateRoute key='/reports' path='/reports' exact component={TimedLogoutComponent(ReportsMenuScreen)} />,
  <PrivateRoute key='/select-report' path='/select-report' exact component={TimedLogoutComponent(SelectReport)} />,
  <Route key='/reports/new/public' path='/reports/new/public/:orgId' exact component={NewReportScreen} />,
  <PrivateRoute key='/reports/:id' path='/reports/:id' exact component={TimedLogoutComponent(NewReportScreen)} />,
  <PrivateRoute key='/reports/share/:extReportId' path='/reports/share/:extReportId' component={TimedLogoutComponent(ReportsShareScreen)} />,
  <PrivateRoute key='/reports/permalink/:id' path='/reports/permalink/:id' exact component={TimedLogoutComponent(SingleReportScreen)} />,
  <PrivateRoute key='/reports/:id/notification/:notificationId' path='/reports/:id/notification/:notificationId' exact component={TimedLogoutComponent(NewReportScreen)} />,
  <PrivateRoute key='/reports/:reportId/review/:submissionReportId' path='/reports/:id/review/:submissionReportId' exact component={TimedLogoutComponent(NewReportBeta)} />,
  <PrivateRoute key='/reports/:reportId/review/anonymised/:submissionReportId' path='/reports/:id/review/anonymised/:submissionReportId' exact component={TimedLogoutComponent(NewReportBeta)} />,
  <PrivateRoute key='/reports/post' path='/reports/post' exact component={TimedLogoutComponent(ReportsMenuScreen)} />,
  <PrivateRoute key='/post-report' path='/post-report' exact component={TimedLogoutComponent(AdvancedPostReport)} />
]

export default routes