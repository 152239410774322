import React, { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { utilities, constants as formSchemaConstants } from '@redant/mhra-form-schema-library'
import ClientSDK from '@redant/digital-store-client-sdk-mhr'

import { actions, initialState, reducer } from './reducer'

export const SdkContext = React.createContext()

const commonProps = ({ props, fields, state }) => {
  const { formContext } = state
  const { formView, formSchemaId } = formContext

  return {
    formSchemaId: formSchemaId || _.get(formView, 'formSchemaId'),
    formViewVersionId: _.get(formView, 'formViewVersions.0.id'),
    userId: _.get(props, 'user.id'),
    organisationId: _.get(props, 'organisationId'),
    reportJSON: fields,
    source: _.get(props, 'source'),
    sourceId: _.get(props, 'sourceId'),
    uploadType: _.get(state, 'attachmentUploadType'),
    attachmentFields: _.get(state, 'attachmentFields')
  }
}

const SdkProvider = props => {
  const { children, auth } = props
  const [state, dispatch] = useReducer(reducer, initialState)
  const [authContext, setAuthContext] = useState()

  const sdk = new ClientSDK({
    baseUrl: props.baseUrl
  })

  useEffect(() => {
    setAuthContext(auth)
  }, [auth])

  if (authContext) {
    sdk.auth.setCredentials(authContext)
  }

  const getSearchParams = ({ query, size = 50, page = 1, lookupOptions }) => {

    return {
      query,
      size,
      organisationId: _.get(props, 'organisationId'),
      languageCode: _.get(props, 'languageCode'),
      watchListOnly: null,
      page,
      ...lookupOptions,
      reference: lookupOptions?.listName
    }
  }

  const fetchReportById = ({ reportId, attributes = [] }) => {
    return sdk.reports.fetchReportById({
      id: reportId,
      attributes,
      includeApplications: false,
      includeSubmissionReports: false,
      includeFormviews: false,
      includeReportSummary: false
    })
  }

  return <SdkContext.Provider value={{
    sdk,
    organisationId: props.organisationId,
    formViewId: props.formViewId,
    languageCode: props.languageCode,
    user: props.user,
    saveDraftReport: async ({ values }) => {
      const fields = _.cloneDeep(values)
      const { reportId } = state.formContext

      if (reportId) {
        const reportInstance = await fetchReportById({ reportId, attributes: ['status'] })
        const isDraft = reportInstance.status === 'draft'

        const payload = {
          ...commonProps({ fields, props, state }),
          reportId,
          status: 'draft'
        }

        if (isDraft) {
          return sdk.reports.updateReport(payload)
        } else {
          return sdk.reports.sendReport(payload)
        }
      } else {
        const payload = {
          ...commonProps({ fields, props, state }),
          status: 'draft'
        }

        return sdk.reports.sendReport(payload)
      }
    },
    postReport: async ({ values }) => {
      let report
      const { reportId, followUp } = state.formContext

      const hasReportId = _.isString(reportId)
      if (hasReportId) {
        report = await sdk.reports
          .fetchReportById({
            id: reportId,
            attributes: [
              'id',
              'status'
            ]
          })
      }
      const currentReportStatus = _.get(report, 'status')
      const fields = _.cloneDeep(values)
      const status = utilities.getReportStatus(fields)
      const sdkMethod = hasReportId && currentReportStatus === 'draft' ? 'updateReport' : 'sendReport'

      const payload = {
        ...commonProps({ fields, props, state }),
        status
      }

      if (reportId) {
        payload.reportId = reportId
      }

      if (followUp) {
        payload.followUpUserId = _.get(followUp, 'id')
      }

      return sdk.reports[sdkMethod](payload)
    },
    postAcknowledgement: async ({ values }) => {
      const { reportId, formSchema } = state.formContext
      let ackJSON

      switch (formSchema) {
        case formSchemaConstants.FORM_SCHEMAS_CONSTANTS.MHRA_R3_ACK:
          const messageFields = [
            'batchnumber',
            'batchsenderidentifier',
            'batchreceiveridentifier',
            'batchtransmissiondate',
            'icsrbatchnumber',
            'localmessagenumber',
            'icsrbatchtransmissiondate',
            'transmissionacknowledgmentcode',
            'batchvalidationerror'
          ]

          const safetyReportFields = [
            'icsrmessagenumber',
            'localreportnumber',
            'icsrmessagesenderidentifier',
            'icsrmessagereceiveridentifier',
            'icsrcreationdate',
            'icsracknowledgementcode',
            'errormessage'
          ]

          ackJSON = {
            ..._.pick(values, messageFields),
            messageAcknowledgements: [_.pick(values, safetyReportFields)]
          }
          break
        default:
          ackJSON = values
          break
      }

      const payload = {
        reportId,
        ackJSON,
        formSchema
      }

      return sdk.acks.sendAck(payload)
    },
    drugSearch: (params) => {
      return sdk.drugs.searchDrugs(getSearchParams(params))
    },
    medDRASearch: (params) => {
      return sdk.meddras.searchMeddras(getSearchParams(params))
    },
    edqmSearch: (params) => {
      return sdk.edqm.searchEdqm(getSearchParams(params))
    },
    locationSearch: async (params) => {
      const locations = await sdk.organisations.fetchLocationsForOrganisation(getSearchParams(params))
      return locations.results.rows.map((row) => { return { id: row.id, name: row.name, code: row.name } })
    },
    terminologySearch: async (params) => {
      const dictionaryEntryResults = await sdk.terminology.searchTerminologyEntriesByReference(getSearchParams(params))
      return dictionaryEntryResults.rows.map((row) => { return { id: row.id, name: row.definition, code: row.term } })
    },
    hospitalSearch: (params) => {
      return sdk.hospitals.searchHospitals(getSearchParams(params))
    },
    organisationSearch: (params) => {
      return sdk.organisations.searchOrganisations(getSearchParams(params))
    },
    login: ({ email, username, password }) => {
      return sdk.auth.login({ email, username, password, platformId: props.platformId })
    },
    register: (data) => {
      return sdk.users.createUser(data)
    },
    updateUser: (data) => {
      return sdk.users.updateUser(data)
    },
    resetPassword: ({ email, themeId }) => {
      return sdk.auth.resetPassword({ email, websiteId: props.websiteId, platformId: props.platformId, themeId })
    },
    changePassword: ({ resetToken, password }) => {
      return sdk.auth.changePassword({ resetToken, password })
    },
    changeCurrentUserPassword: ({ password, currentPassword }) => {
      return sdk.auth.changePasswordByUserId({ id: '@me', password, currentPassword })
    },
    findLocation: ({ latitude, longitude }) => {
      return sdk.location.findLocation({ organisationId: props.organisationId, latitude, longitude })
    },
    fetchOrganisationLocations: ({ query }) => {
      return sdk.location.fetchOrganisationLocations({ organisationId: props.organisationId, query })
    },
    syncOrganisationLocations: () => {
      return sdk.location.syncOrganisationLocations({ organisationId: props.organisationId })
    },
    setAttachmentFields: (attachmentFields) => {
      dispatch({
        type: actions.SET_ATTACHMENT_FIELDS,
        payload: {
          attachmentFields
        }
      })
    },
    setFormContext: ({ formSchemaId, formSchema, formView, reportId, followUp }) => {
      dispatch({
        type: actions.SET_FORM_CONTEXT,
        payload: {
          formSchemaId,
          formSchema,
          formView,
          reportId,
          followUp
        }
      })
    },
    fetchDownloadUrl: ({fileName, contentType}) => {
      return sdk.auth.fetchDownloadUrl({ fileName, contentType })
    },
  }}>
    {children}
  </SdkContext.Provider>
}

SdkProvider.propTypes = {
  /**
   * Child components to provide context to.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /**
   * The URL for the Vigilance Hub API.
   * Is used to initialize the Digital Store SDK.
   */
  baseUrl: PropTypes.string.isRequired,
  /**
   * The organisation's ID from Vigilance Hub.
   * Is used with the `baseUrl` for organisation based customisations such as MedDRA Dictionary.
   */
  organisationId: PropTypes.string.isRequired,
  /**
   * The client's chosen language.
   * Is used for the MedDRA Dictionary Service.
  */
  languageCode: PropTypes.string
}

export default SdkProvider

